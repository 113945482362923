export const FacilityType = {
  TVS: 'TVS',
  CNV: 'CNV',
  PT: 'PT'
}

export const translateFacilityType = (facilityType) => {
  switch (facilityType) {
    case FacilityType.TVS:
      return '直営施設'
    case FacilityType.CNV:
      return 'HCV施設'
    case FacilityType.PT:
      return 'ポイント提携施設'
    default:
      return ''
  }
}

export const getOrderOfFacilityType = (facilityType) => {
  switch (facilityType) {
    case FacilityType.TVS:
      return 1
    case FacilityType.CNV:
      return 2
    case FacilityType.PT:
      return 3
    default:
      return 4
  }
}
