<template>
  <v-dialog v-model="isShow" width="250px" @click:outside="closeDialog">
    <v-card class="accom-price-updation-all">
      <v-radio-group v-model="changeType" row hide-details>
        <v-radio label="割合" value="Percent"></v-radio>
        <v-radio label="固定額" value="Fixed"></v-radio>
      </v-radio-group>
      <v-row class="target-prices">
        <div>
          <v-select
            :items="$options.calculation"
            item-text="text"
            item-value="value"
            v-model="calculation"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
        </div>
        <div>
          <v-text-field
            v-model="change"
            dense
            color="var(--text__gray)"
            outlined
            hide-details
            single-line
            min="0"
            type="number"
          ></v-text-field>
        </div>
        <label class="text--small mr-1" v-if="changeType === 'Percent'"
          >%</label
        >
        <button
          class="outside-click-exclude"
          @click="showSelectAccomProduct = true"
        >
          適用商品料金
        </button>
      </v-row>
      <v-row class="fraction-row">
        <v-col cols="12" sm="6">
          <v-select
            :items="$options.fraction"
            item-text="text"
            item-value="value"
            v-model="fraction"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="roomTypeList"
            item-text="name"
            item-value="id"
            v-model="targetRoomTypeIds"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
        </v-col>
      </v-row>
      <p class="title">適用範囲</p>
      <p>{{ formatDate(stayDateUpdationAll) }}から</p>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <div>
            <v-icon color="#000000">mdi-calendar-month</v-icon>
            <v-btn v-model="dateTo" v-on="on" small class="btn--date mr-5">
              {{ formatDate(dateTo) }}
            </v-btn>
          </div>
        </template>
        <v-date-picker
          v-model="dateTo"
          @input="menu = false"
          :min="stayDateUpdationAll"
        ></v-date-picker>
      </v-menu>
      <div class="days-of-weeks">
        <v-checkbox
          v-for="day in $options.dayOfWeek"
          multiple
          v-model="days"
          :key="day.value"
          :value="day.value"
          :label="day.text"
          hide-details
        ></v-checkbox>
      </div>
      <v-card-actions>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!change"
          @click="updatePrice"
          >変更</v-btn
        >
        <v-btn class="ma-2" text color="gray" elevation="0" @click="closeDialog"
          >キャンセル</v-btn
        >
      </v-card-actions>
      <div
        class="select-accom-product"
        v-outside-click="{
          exclude: ['outside-click-exclude'],
          handler: clickOutSide,
        }"
        v-if="showSelectAccomProduct"
      >
        <a style="font-size: 13px; color: #1867C0; height: 11px; width: 33px" href="" @click.prevent="selectAll">全選択</a>
        <a style="font-size: 13px; color: #C92D2D; height: 11px; width: 33px" href="" @click.prevent="deselectAll">全解除</a>
        <v-checkbox
          v-for="target in targetPriceTypes"
          multiple
          v-model="targets"
          :key="target.type"
          :value="target.type"
          :label="target.text"
          hide-details
        ></v-checkbox>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// api
import { UPDATE_ACCOMMODATION_PRICE } from '@/api/graphql/accommodation/updateAccommodationPrice'
// util
import {
  formatDate,
  formatOnlyDate,
  formatCurrency,
  handlErrorView, getCopyErrorTextView
} from '@/constants/functions'
import { TARGET_PRICE_TYPES } from '@/constants/accommondation'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'AccommodationPriceUpdationAll',
  calculation: [
    {
      text: '+',
      value: 1
    },
    {
      text: '-',
      value: -1
    }
  ],
  fraction: [
    {
      text: '小数点切り上げ',
      value: 'Ceil'
    },
    {
      text: '小数点切り下げ',
      value: 'Floor'
    },
    {
      text: '小数点四捨五入',
      value: 'Round'
    }
  ],
  dayOfWeek: [
    {
      text: '月',
      value: 0
    },
    {
      text: '火',
      value: 1
    },
    {
      text: '水',
      value: 2
    },
    {
      text: '木',
      value: 3
    },
    {
      text: '金',
      value: 4
    },
    {
      text: '土',
      value: 5
    },
    {
      text: '日',
      value: 6
    }
  ],
  props: {
    isShowUpdationAll: {
      type: Boolean,
      required: false,
      default: true
    },
    stayDateUpdationAll: [Date, String],
    roomTypeList: Array
  },
  data () {
    return {
      //
      menu: false,
      isShow: false,
      //
      change: undefined,
      changeType: 'Percent',
      calculation: 1,
      fraction: 'Ceil',
      targetRoomTypeIds: [],
      dateTo: new Date().toISOString().substr(0, 10),
      // dayOfWeek
      days: [0, 1, 2, 3, 4, 5, 6],
      // modals
      showSelectAccomProduct: false,
      targetPriceTypes: TARGET_PRICE_TYPES,
      targets: []
    }
  },
  computed: {
    getPriceTypeKeys () {
      return this.targetPriceTypes.map((target) => target.type)
    }
  },
  watch: {
    isShowUpdationAll (value) {
      this.isShow = value
    },
    roomTypeList (value) {
      if (value[0]) this.targetRoomTypeIds = value[0].id
    },
    stayDateUpdationAll (value) {
      this.dateTo = value
    }
  },
  methods: {
    formatCurrency,
    formatDate,
    formatOnlyDate,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    async updatePrice () {
      const typesObject = {}
      this.getPriceTypeKeys.forEach(
        (type) => (typesObject[type] = this.targets.includes(type))
      )
      this.setLoadingOverlayShow()
      const variables = {
        changeType: this.changeType,
        fraction: this.fraction,
        change: this.calculation * this.change,
        targetPriceTypes: typesObject,
        targetRoomTypeIds: this.targetRoomTypeIds,
        dateFrom: this.stayDateUpdationAll,
        dateTo: this.dateTo,
        dayOfWeek: this.days
      }
      await this.$apollo
        .mutate({
          mutation: gql`${UPDATE_ACCOMMODATION_PRICE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then((res) => {
          if (res.data.updateAccommodationPrice) this.$emit('refetch')
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
        })
        .catch((error) => {
          this.setAlertError(this.$t('messages.updateError'))
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_ACCOMMODATION_PRICE, variables, error.graphQLErrors))
          // const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          // if (errorTmp) {
          //   this.setAlertError(errorTmp, { root: true })
          // } else {
          //   this.setAlertError(this.$t('messages.createError'))
          // }
        })
        .finally(() => {
          this.closeDialog()
          this.setLoadingOverlayHide()
        })
    },
    closeDialog () {
      this.$emit('update:isShowUpdationAll', false)
      this.resetData()
      this.$emit('close')
    },
    resetData () {
      this.change = undefined
      this.changeType = 'Percent'
      this.calculation = 1
      this.fraction = 'Ceil'
      this.dateTo = new Date().toISOString().substr(0, 10)
      this.days = [0, 1, 2, 3, 4, 5, 6]
      this.showSelectAccomProduct = false
      this.targetPriceTypes = TARGET_PRICE_TYPES
      this.targets = []
    },
    selectAll () {
      this.targets = [...this.getPriceTypeKeys]
    },
    deselectAll () {
      this.targets = []
    },
    clickOutSide () {
      if (this.showSelectAccomProduct) this.showSelectAccomProduct = false
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  padding: 11px 13.5px 9px 13.5px;
  color: #616161;
  min-height: 83px;

  p {
    line-height: 1.2;
    margin-bottom: 4px;
  }

  p.title {
    font-weight: bold;
    font-size: 12px !important;
  }

  p:not(.title) {
    color: #aaaaaa;
    font-size: 11px;
  }
}

.v-card__title {
  display: block !important;
  padding: 0 !important;
  background-color: white !important;
}

.v-card__actions {
  padding: 0 !important;
  justify-content: flex-start !important;
  gap: 9px;

  button {
    height: 24px !important;
    font-size: 16px !important;
    margin: 0 !important;

    &:last-child {
      border: 1px solid #bdbdbd !important;
      color: #616161 !important;
    }
  }
}

.outside-click-exclude{
  color: #aaa !important;
}
::v-deep{
  .v-input--checkbox{
    .v-label{
      font-size: 13px !important;
      color: #AAAAAA !important;
      height: 15px;
    }
    .v-icon {
      color: #757575;
      height: 14px;
      width: 14px;
    }
    .primary--text {
      color: #1867C0 !important;
    }
  }
  .v-input{
    .v-select__selections{
      .v-select__selection--comma{
        font-size: 13px !important;
        color: #aaa !important;
      }
    }
    .v-text-field__slot{
      input{
        color: #aaa !important;
      }
    }
  }
  .v-list-item{
    .v-list-item__content{
      .v-list-item__title{
        color: #aaa !important;
      }
    }
  }
  .btn--date{
    font-size: 13px !important;
      .v-btn__content{
        color: #aaa !important;
      }
  }
}
</style>

<style lang="scss">
div.accom-price-updation-all {
  position: relative;
  .v-input--radio-group {
    margin-top: 0;

    label {
      font-size: 14px;
      color: #444444 !important;
      font-weight: 500;
    }

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls__input {
      width: 20px !important;
      height: 20px !important;
    }

    .v-input--selection-controls__ripple {
      width: 32px !important;
      height: 30px !important;
      top: calc(50% - 22px) !important;
      left: -13px !important;
    }
  }

  div.target-prices {
    margin: 8px 0;
    & > div {
      height: 28px !important;
      &:first-child {
        width: 32px;
        margin-right: 3px;

        .v-input__slot {
          padding-left: 6px !important;
        }

        .v-select__selection {
          width: 13px !important;
        }

        input {
          width: 0px !important;
          max-width: 10px !important;
        }

        .v-input__append-inner {
          position: relative;
          left: -15px;
          width: 10px;
          top: 1px;
        }
      }

      &:nth-child(2) {
        width: 65px;
        margin-right: 3px;

        fieldset {
          height: 33px !important;
        }

        .v-text-field__slot {
          height: 27px !important;
        }
      }
    }

    & > label {
      line-height: 28px;
    }

    button {
      width: 87px;
      height: 28px;
      font-size: 13px;
      margin: 0;
      border: 1px solid #9e9e9e;
      border-radius: 3px;
      &:hover {
        background-color: rgb(226, 226, 226);
      }
    }
  }

  div.fraction-row {
    margin-bottom: 16px;
    margin-top: 0 !important;
    & > div {
      &:first-child {
        padding: 0 3px 0 12px;
      }
      &:last-child {
        padding: 0 12px 0 3px;
      }
    }
  }

  div.days-of-weeks {
    display: flex;
    flex-direction: row;
    .v-input--selection-controls__input {
      width: 14px !important;
      height: 14px !important;
      margin: 2px !important;
    }

    .v-input {
      margin-top: 0px;
      height: 33px;
    }

    .v-label {
      font-size: 14px !important;
    }
  }
}

div.select-accom-product {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 194px;
  max-height: 223px;
  padding: 3px 8px;
  background-color: white;
  transform: translateY(-25%) translateX(17px);
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaaaaa;
  }

  & a {
    font-size: 13px;
    &:first-child {
      color: #1867c0;
      margin-right: 9px;
    }

    &:not(:first-child) {
      color: #c92d2d;
    }
  }

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    &:first-of-type {
      margin-top: 6px;
    }
  }

  .v-input--selection-controls__input {
    width: 14px !important;
    height: 14px !important;
    margin: 2px !important;
  }

  .v-input--selection-controls__ripple {
    height: 20px !important;
    width: 20px !important;
    left: -10px !important;
    top: calc(50% - 18px) !important;
  }
}
</style>
