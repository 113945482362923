export const UPDATE_ACCOMMODATION_PRICE = `
  mutation (
    $changeType: AccommodationPriceChangeType!
    $fraction: AccommodationPriceFraction!
    $change: Int!
    $targetPriceTypes: UpdateAccommodationPriceTargetPricesInput!
    $targetRoomTypeIds: [Int!]!
    $dateFrom: DateString!
    $dateTo: DateString!
    $dayOfWeek: [Int!]!
  ) {
    updateAccommodationPrice(
      data: {
        changeType: $changeType
        change: $change
        targetPriceTypes: $targetPriceTypes
        targetRoomTypeIds: $targetRoomTypeIds
        fraction: $fraction
        dateFrom: $dateFrom
        dateTo: $dateTo
        dayOfWeek: $dayOfWeek
      }
    ) {
      id
    }
  }
`
