<template>
  <v-dialog v-model="isShow" width="230px" @click:outside="closeDialog">
    <v-card>
      <v-text-field
        type="number"
        v-model="price"
        dense
        prepend-inner-icon="mdi-currency-cny"
        class="price-input"
      />
      <v-card-actions>
        <v-btn color="primary" type="submit" :disabled="!price || price < 0" @click="updatePrice">変更</v-btn>
        <v-btn class="ma-3" text color="gray" elevation="0" @click="$emit('updateAll', selectedAccomItem.stayDate); closeDialog()">まとめて変更</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// api
import { UPDATE_ACCOMMODATION_PRICE_CELLS } from '@/api/graphql/accommodation/updateAccommodationPriceCells'
// util
import {
  formatDate,
  formatOnlyDate,
  formatCurrency
} from '@/constants/functions'

export default {
  name: 'AccommodationPriceUpdation',
  props: {
    isShowPriceUpdation: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedAccomItem: Object
  },
  data () {
    return {
      isShow: false,
      price: undefined
    }
  },
  mounted () {},
  watch: {
    isShowPriceUpdation (value) {
      this.isShow = value
    },
    'selectedAccomItem.toPrice' (value) {
      this.price = value
    }
  },
  methods: {
    formatCurrency,
    formatDate,
    formatOnlyDate,
    async updatePrice () {
      await this.$apollo
        .mutate({
          mutation: UPDATE_ACCOMMODATION_PRICE_CELLS,
          variables: {
            toPrice: Number(this.price || 0),
            cells: [
              {
                stayDate: this.selectedAccomItem.stayDate,
                roomTypeId: this.selectedAccomItem.roomTypeId,
                priceColumn: this.selectedAccomItem.priceColumn
              }
            ]
          },
          fetchPolicy: 'no-cache'
        })
        .then((res) => {
          if (res.data.updateAccommodationPriceCells) {
            this.closeDialog()
            // eslint-disable-next-line no-unused-expressions
            this.selectedAccomItem.updatePrice?.(this.price)
          }
        })
    },
    closeDialog () {
      this.$emit('update:isShowPriceUpdation', false)
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  padding: 11px 13.5px 9px 13.5px;
  color: #616161;
  min-height: 90px;
}

.v-card__title {
  display: block !important;
  padding: 0 !important;
  background-color: white !important;
}

.v-input {
  margin-top: 0px;
  height: 33px;
}

.v-card__actions {
  padding: 0 !important;
  justify-content: space-around !important;
  // gap: 9px !important;

  button {
    height: 24px !important;
    font-size: 13px !important;
    margin: 0 !important;

    &:last-child {
      border: 1px solid #bdbdbd !important;
      color: #616161 !important;
    }
  }
}

::v-deep{
  .v-text-field__slot{
    input{
      font-size: 15px !important;
      color: #212121 !important;
    }
  }
  .v-card__actions{
    button{
      font-size: 16px !important;
    }
  }
  .mdi-currency-cny{
    font-size: 20px !important;
    color: #212121 !important
  }
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before{
    border: 2px solid #1967C0 !important;
  }
}
</style>
