<template>
  <base-fees-points>
    <template v-slot:default>
      <!-- header -->
      <div class="fees-and-points flex-header px-4 mt-5">
        <v-btn @click="prevMonth" icon>
          <v-icon size="35">mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-btn @click="nextMonth" icon>
          <v-icon size="35">mdi-chevron-double-right</v-icon>
        </v-btn>
        <v-btn
          @click="chooseToday"
          outlined
          small
          class="mr-5 ml-1"
          color="var(--text__gray)"
        >
          {{ $t("common.toDay") }}
        </v-btn>
        <!-- datetime -->
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <div>
              <v-icon color="#000000">mdi-calendar-month</v-icon>
              <v-btn v-model="fromDate" v-on="on" small class="btn--date mr-5">
                {{ formatDate(fromDate) }}
              </v-btn>
            </div>
          </template>
          <v-date-picker
            v-model="fromDate"
            :first-day-of-week="0"
            :locale="$i18n.locale"
            scrollable
            @input="menuDate = false"
            class="v-date-picker-custom"
          ></v-date-picker>
        </v-menu>
        <label class="text--small mr-1">{{
          $t("roomAssignment.period")
        }}</label>
        <v-select
          :items="$options.timeRange"
          item-text="text"
          item-value="value"
          v-model="rangeTime"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom"
        ></v-select>
        <!-- datetime -->
        <!-- facility -->
        <label class="ml-5 text--small mr-1">{{ $t("common.facility") }}</label>
        <v-select
          :items="facilityListItem"
          v-model="idSelectedFacility"
          item-text="name"
          item-value="id"
          :value-comparator="arrayEqual"
          dense
          small
          color="var(--text__gray)"
          placeholder="未選択"
          outlined
          hide-details
          class="v-select-custom facility-list"
        >
          <template v-slot:item="{ item }">
            <div class="facility-select-item" :class="item.cssClass">{{ item.name }}</div>
          </template>
        </v-select>
        <!-- facility -->
        <!-- checkboxes -->
        <span class="ml-3 mr-1 fs-14">表示料金</span>
        <v-checkbox label="商品宿泊" v-model="isShowAccomList" dense />
        <v-checkbox
          label="プラン"
          class="mx-2"
          v-model="isShowBookingList"
          dense
        />
        <v-checkbox label="商品外" v-model="isOutOfStock" dense />
        <span class="ml-3 mr-1 fs-14">強調表示</span>
        <!-- checkboxes -->
        <!-- prices -->
        <v-text-field
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          single-line
          min="0"
          type="number"
          @keyup="keyupSearchInput($event, true)"
        ></v-text-field>
        <label class="text--xsmall ml-1 mr-2">円以上</label>
        <v-text-field
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          single-line
          :min="minPrice"
          type="number"
          @keyup="keyupSearchInput($event, false)"
        ></v-text-field>
        <label class="text--xsmall ml-1">円以上</label>
        <!-- prices -->
      </div>
      <!-- header -->
      <!-- body -->
      <div class="t-wrapper" v-if="roomTypeList.length > 0">
        <div class="t-calendar">
          <!-- month-->
          <div class="t-calendar__header">
            <div class="t-calendar__name">
              <h3 class="text-title">
                {{ selectedFacility ? selectedFacility.name : "全て" }}
              </h3>
              <!-- ------------- -->
            </div>
            <div class="t-calendar__header-list">
              <div
                v-for="(month, index) in getMonthsList"
                :key="index"
                :style="month.style"
              >
                {{ month.value }}
              </div>
            </div>
          </div>
          <!-- month-->
          <div v-for="(roomType, idx) in roomTypeList" :key="idx">
            <room-type-calendar
              :roomTypeLastIndexId="idx > 0 ? roomTypeList[idx - 1].id : null"
              v-bind="{ fromDate, toDate }"
              :roomTypeId="roomType.id"
              :selectedFacility="selectedFacility && selectedFacility.id && !selectedFacility.id.length"
              hideMwCol
              hideCancelWait
            >
              <template v-slot:default="{ dateColWidth, roomType: roomTypeProp }">
                <fees-and-points-room-type
                  ref="roomTypeCalendar"
                  v-bind="{
                    facility: roomTypeProp.facility,
                    fromDate,
                    toDate,
                    roomType,
                    minPrice,
                    maxPrice,
                    isShowAccomList,
                    isShowBookingList,
                    isOutOfStock,
                    nonProductList
                  }"
                  @hoverAccomItem="hoverAccomItem"
                  @leaveAccomItem="leaveAccomItem"
                  @clickAccomItem="clickAccomItem"
                  :dateColWidth="dateColWidth"
                />
              </template>
            </room-type-calendar>
          </div>
        </div>
      </div>
      <div v-else class="text-center py-15 my-15 text-h6">
        {{ $t("rules.noData") }}
      </div>
      <!-- body -->
      <!-- modal -->
      <AccommodationPriceHistoryList
        :isShowPriceHistoryModal.sync="isShowPriceHistoryModal"
        v-bind="{
          hoveredAccom,
        }"
        v-show="isShowPriceHistoryModal"
      />
      <AccommodationPriceUpdation
        :isShowPriceUpdation.sync="isShowPriceUpdation"
        v-bind="{
          selectedAccomItem,
        }"
        @updateAll="updateAllAccom"
      />
      <AccommodationPriceUpdationAll
        :isShowUpdationAll.sync="isShowUpdationAll"
        v-bind="{ stayDateUpdationAll, roomTypeList }"
        @refetch="resetAndGetNewData"
      />
      <!-- modal -->
    </template>
  </base-fees-points>
</template>

<script>
import { mixin } from './mixin'
import gql from 'graphql-tag'
import { arrayEqual } from '@/utils/validators'

export default {
  mixins: [mixin],
  data () {
    return {
      facilityList: [],
      arrayEqual
    }
  },
  computed: {
    roomTypeList () {
      return this.facilityList.filter(f => {
        return this.idSelectedFacility && this.idSelectedFacility.includes(f.id)
      }).reduce((arr, facility) => {
        arr = arr.concat(facility.roomTypes)
        return arr
      }, [])
    }
  },
  methods: {
    async resetAndGetNewData (roomTypeIds) {
      if (roomTypeIds && !Array.isArray(roomTypeIds)) {
        roomTypeIds = [roomTypeIds] // to support single value
      }
      for (const rt of this.$refs.roomTypeCalendar) {
        if (!roomTypeIds || roomTypeIds.includes(rt.roomTypeId)) { rt.reload() }
      }
    }
  },
  apollo: {
    facilityList: {
      query: gql`query {
        facilityList (loadRoomType: true) {
          id
          name
          shortName
          type
          roomTypes {
            id name facilityId
          }
        }
      }`,
      update: (data) => data.facilityList
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 12px !important;
  font-weight: 500;
  color: #000 !important;
}
::v-deep {
  .v-input--checkbox {
    flex-shrink: 0 !important;
    .v-label {
      flex-shrink: 0 !important;
      color: #000000 !important;
      font-size: 12px !important;
    }
  }
  .t-calendar-item__data-item {
    font-size: 16px !important;
  }
  .v-btn--outlined {
    .v-btn__content {
      font-size: 10px !important;
      color: #000 !important;
      font-weight: 500;
    }
  }
  .text--small {
    color: #000 !important;
    font-weight: 500;
  }
  .fees-and-points {
    .fs-14 {
      color: #000 !important;
    }
  }
  .t-calendar__header-list {
    div {
      font-size: 11px !important;
      font-weight: 500;
      color: #000 !important;
    }
  }
}

.fees-and-points {
  .v-text-field--single-line fieldset {
    height: 28px !important;
  }
}

.facility-select-item {
  padding-left: 10px;
  &.select-item-facility-type {
    padding-left: 0;
    color: var(--text__primary);
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.fees-and-points {
  .v-text-field--single-line {
    position: relative;
    top: 6px;

    fieldset {
      height: 33px !important;
    }

    .v-text-field__slot {
      height: 28px;
    }
  }
}
.t-calendar-item__title {
  font-size: 14px !important;
}
</style>
