<template>
  <div v-outside-click="{
          exclude: ['outside-click-exclude'],
          handler: clickOutSide,
        }" class="accom-price-history-list" :style="styleObject">
    <v-card>
      <v-card-title>
        <p>変更履歴</p>
        <p>
          {{ formatDate(hoveredAccom.stayDate) }} {{ hoveredAccom.name }} 料金
        </p>
      </v-card-title>

      <div class="price-histories">
        <div class="column-title">
          <div>日付</div>
          <div>料金</div>
        </div>
        <div
          v-for="(history, index) in accommodationPriceHistoryList"
          :key="index"
        >
          <div>{{ formatOnlyDate(history.createdAt) }}</div>
          <div>{{ formatCurrency(history[hoveredAccom.priceKey] || 0) }}</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// api
import { ACCOMMODATION_PRICE_HISTORY_LIST } from '@/api/graphql/accommodation/accommodationPriceHistoryList'
// util
import {
  formatDate,
  formatOnlyDate,
  formatCurrency
} from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  name: 'AccommodationPriceHistoryList',
  props: {
    isShowPriceHistoryModal: {
      type: Boolean,
      required: false,
      default: true
    },
    hoveredAccom: Object
  },
  data () {
    return {
      isShow: false,
      accommodationPriceHistoryList: []
    }
  },
  computed: {
    styleObject () {
      const position = this.hoveredAccom.position
      if (!position) return {}

      return {
        left: `${position.left + 100 / 2}px`,
        top: `${position.top + position.height}px`
      }
    }
  },
  watch: {
    isShowPriceHistoryModal (value) {
      this.isShow = value
    },
    'hoveredAccom.stayDate' () {
      this.getPriceHistory()
    },
    'hoveredAccom.roomTypeId' () {
      this.getPriceHistory()
    }
  },
  methods: {
    formatCurrency,
    formatDate,
    formatOnlyDate,
    async getPriceHistory () {
      await this.$apollo
        .query({
          query: gql`${ACCOMMODATION_PRICE_HISTORY_LIST}`,
          variables: {
            stayDate: this.hoveredAccom.stayDate,
            roomTypeId: this.hoveredAccom.roomTypeId
          }
        })
        .then((res) => {
          this.accommodationPriceHistoryList =
            res.data.accommodationPriceHistoryList
        })
    },
    clickOutSide () {
      if (this.isShowPriceHistoryModal) this.closeDialog()
    },
    closeDialog () {
      this.$emit('update:isShowPriceHistoryModal', false)
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
div.accom-price-history-list {
  position: fixed;
  width: 300px;
  left: 270px;
  top: 70px;
  z-index: 100;
}

.v-card {
  padding: 10px;
  color: #616161;
}

.v-card__title {
  display: block !important;
  padding: 0 !important;
  background-color: white !important;

  p {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 0;
  }

  p:last-child {
    color: #aaaaaa;
    margin-top: 8px;
  }
}

.price-histories {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  .column-title{
    div{
      font-size: 14px !important;
      font-weight: bold;
      color: #757575 !important;
    }
  }
  & > div {
    height: 38px;
    border-bottom: 1px solid rgb(0 0 0 / 8%);
    display: flex;
    flex-direction: row;

    & > * {
      width: 50%;
      // text-align: center;
      padding-left: 10px;
      padding-top: 11px;
      font-size: 16px;
      color: #757575 !important;
    }
  }
}
</style>
