export const TARGET_PRICE_TYPES = [
  {
    type: 'vs1',
    text: 'VS1人'
  },
  {
    type: 'vs2',
    text: 'VS2人'
  },
  {
    type: 'vs3',
    text: 'VS3人'
  },
  {
    type: 'vs4',
    text: 'VS4人'
  },
  {
    type: 'vs5',
    text: 'VS5人'
  },
  {
    type: 'vs6',
    text: 'VS6人'
  },
  {
    type: 'vs7',
    text: 'VS7人'
  },
  {
    type: 'vs8',
    text: 'VS8人'
  },
  {
    type: 'vs9',
    text: 'VS9人'
  },
  {
    type: 'vs10',
    text: 'VS10人'
  },
  {
    type: 'vm1',
    text: 'MO1人'
  },
  {
    type: 'vm2',
    text: 'MO2人'
  },
  {
    type: 'vm3',
    text: 'MO3人'
  },
  {
    type: 'vm4',
    text: 'MO4人'
  },
  {
    type: 'vm5',
    text: 'MO5人'
  },
  {
    type: 'vm6',
    text: 'MO6人'
  },
  {
    type: 'vm7',
    text: 'MO7人'
  },
  {
    type: 'vm8',
    text: 'MO8人'
  },
  {
    type: 'vm9',
    text: 'MO9人'
  },
  {
    type: 'vm10',
    text: 'MO10人'
  },
  {
    type: 'sp',
    text: 'SP'
  },
  {
    type: 'fp',
    text: 'FP'
  },
  {
    type: 'dp',
    text: 'DP'
  }
]
