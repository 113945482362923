<template>
  <div :class="isHoliday ? 'dow-0' : `dow-${dow}`" style="font-size: 10px; color: #000000">
    <!-- {{ dateNum }}({{ dow | jaDayFromDay}}) -->
    {{ dateNum }}({{ dow | jaDayFromDay}})
  </div>
</template>

<script>
export default {
  props: {
    date: String,
    isHoliday: Boolean
  },
  computed: {
    dow () {
      return this.$dow(this.date)
    },
    dateNum () {
      return this.$date(this.date)
    }
  }
}
</script>

<style lang="scss" scoped>
.dow-0 {
  color: red !important;
}
</style>
