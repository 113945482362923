import { render, staticRenderFns } from "./AccommodationPriceHistoryList.vue?vue&type=template&id=220aa302&scoped=true&"
import script from "./AccommodationPriceHistoryList.vue?vue&type=script&lang=js&"
export * from "./AccommodationPriceHistoryList.vue?vue&type=script&lang=js&"
import style0 from "./AccommodationPriceHistoryList.vue?vue&type=style&index=0&id=220aa302&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220aa302",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardTitle})
