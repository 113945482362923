import gql from 'graphql-tag'

export const ACCOMMODATION_PRICE_HISTORY_LIST = gql`query($stayDate: DateString!, $roomTypeId: Int!) {
  accommodationPriceHistoryList(stayDate: $stayDate, roomTypeId: $roomTypeId) {
    createdAt #変更日時
    dpPrice #以下各対応した料金
    spPoint
    fpPoint
    vsPrice1
    vsPrice2
    vsPrice3
    vsPrice4
    vsPrice5
    vsPrice6
    vsPrice7
    vsPrice8
    vsPrice9
    vsPrice10
    vmPrice1
    vmPrice2
    vmPrice3
    vmPrice4
    vmPrice5
    vmPrice6
    vmPrice7
    vmPrice8
    vmPrice9
    vmPrice10
  }
}

`
