<template>
  <div>
    <template v-if="$apollo.loading && false">
      <div>読み込み中</div>
    </template>
    <template v-else-if="roomType">
      <div v-if="roomType.facility &&
          !selectedFacility &&
          $route.path !=='/calendar/room-assignment' &&
          $route.path !== '/invitations-and-coupons-create' &&
          !new RegExp(`^/reservation/calendar`).test($route.path) &&
          roomTypeLastIndex !== roomType.facility.name"
        class="t-calendar-item --header">
        <div class="t-calendar-item__title">{{ roomType.facility.name }}</div>
      </div>

      <div :class="`d-flex room-type-container-${roomType.id}`">
        <component :is="'style'">
          .room-type-container-{{roomType.id}} .t-calendar-item__title::before {
            background-color: #{{roomType.backgroundColor || '999'}}
          }
        </component>
        <div>
          <div class="d-flex t-calendar-item --header room-type-header">
            <div class="t-calendar-item__title color-item__title">
              {{roomType.name}}
            </div>
            <div ref="dateCol" class="t-calendar-item__data-item" v-for="date in dateRange" :key="date">
              <date-cell :date="date" :isHoliday="isHoliday(date)" />
            </div>
          </div>
          <div class="d-flex" v-if="!hideCancelWait">
            <div class="t-calendar-item__title justify-start" style="padding-left: 42px !important; color: #1873D9">
              空室通知件数
            </div>
            <div class="t-calendar-item__data-item" style="font-size:10px; color: #1873D9" v-for="date in dateRange" :key="date">
              {{ cancelWaitCount[date] || 0 }}
            </div>
          </div>
          <div class="d-flex" v-if="!hideMwCol">
            <div class="t-calendar-item__title justify-start" style="padding-left: 42px !important; color: #000">
              MWPW週番号
            </div>
            <div :class="`t-calendar-item__data-item t-calendar-item__data-item-${mwCol.dates.length}`" v-for="mwCol in mwCalendarCols" :key="mwCol.key">
              <template v-if="mwCol.notDefined">-</template>
              <template v-else>{{mwCol.weekNo}}{{mwCol.latterHalf ? '後半' : '前半' }}</template>
            </div>
          </div>
          <slot
            :roomType="roomType"
            :completed="completed"
            :refreshCount="refreshCount"
            :dateRange="dateRange"
            :dateColWidth="dateColWidth"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { getDateRangeArray } from '@/utils/dateUtil'
// import RoomTypeColor from './RoomTypeColor'
import DateCell from './DateCell.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    // RoomTypeColor,
    DateCell
  },
  props: {
    roomTypeLastIndexId: Number,
    roomTypeId: Number,
    fromDate: String,
    toDate: String,
    mwCalendarCols: Array,
    hideMwCol: Boolean,
    hideCancelWait: Boolean,
    selectedFacility: [Number, String, Boolean]
  },
  updated () {
    this.refreshDateColWidth()
  },
  mounted () {
    this.getRoomTypeLastIndex()
    this.refreshDateColWidth()
    this.fetchHolidays()
  },
  data () {
    return {
      holidayDict: {},
      roomTypeLastIndex: '',
      roomType: null,
      subscribers: {},
      cancelWaitCount: {},
      refreshCount: 0,
      dateColWidth: 0
    }
  },
  watch: {
    fromDate () {
      this.fetchHolidays()
    },
    toDate () {
      this.fetchHolidays()
    }
  },
  computed: {
    dateRange () {
      return getDateRangeArray(this.fromDate, this.toDate)
    },
    uniqueId () {
      // Note: this._uid is not considered SSR safe though, so you
      // may want to use some other ID/UUID generator that will
      // generate the same ID server side and client side. Or just
      // ensure you always provide a unique ID to the `id` prop
      return this.id || this._uid
    }
  },
  methods: {
    ...mapMutations([
      'setLoadingOverlayHide',
      'setLoadingOverlayShow'
    ]),

    async getRoomTypeLastIndex () {
      if (this.roomTypeLastIndexId) {
        await this.$apollo.query({
          query: gql`
        query ($roomTypeId: Int!) {
          roomType(id: $roomTypeId) {
            id
            name
            backgroundColor
            facility {
              name
              id
            }
          }
        }
      `,
          variables: {
            roomTypeId: parseInt(this.roomTypeLastIndexId)
          }
        }).then((data) => {
          this.roomTypeLastIndex = data.data.roomType.facility.name
        }).catch((error) => {
          console.error(error)
        })
      }
    },

    refresh () {
      // increment this count, then this data is bound to the component in the slot.
      // so by watching this prop change, the child component can update the data when needed.
      this.refreshCount += 1
    },
    completed (payload) {
      this.$emit('completed', {
        roomTypeId: this.roomTypeId,
        ...payload
      })
    },
    invCountsToArray (invCounts) {
      return this.$store.getters.inventoryCountsToArray(invCounts)
    },
    invCountsToArrayWithExternal (invCounts) {
      const result = this.$store.getters.inventoryTypes.map(it => ({
        ...it,
        count: invCounts[it.id]
      }))
      return result
    },
    refreshDateColWidth () {
      const ref = this.$refs.dateCol?.[0]
      if (!ref) return
      this.dateColWidth = ref.offsetWidth
    },
    async fetchHolidays () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`
            query(
              $from: DateString!
              $to: DateString!
            ) {
              holidays(from: $from, to: $to) {
                holidayDate
                name
              }
            }
          `,
          variables: {
            from: this.fromDate,
            to: this.toDate
          }
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.holidayDict = data.data.holidays.reduce((result, item) => {
            result[item.holidayDate] = item.name
            return result
          }, {})
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          const errorTmp = await this.handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser
          )
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },
    isHoliday (date) {
      return !!this.holidayDict[date]
    }
  },
  apollo: {
    roomType: {
      query: gql`
        query ($roomTypeId: Int!) {
          roomType(id: $roomTypeId) {
            id
            name
            backgroundColor
            facility {
              name
              id
            }
          }
        }
      `,
      update: (data) => data.roomType,
      variables () {
        return {
          roomTypeId: this.roomTypeId
        }
      }
    },
    cancelWaitCount: {
      query: gql`
        query (
          $roomTypeId: Int!
          $fromDate: DateString!
          $toDate: DateString!
        ) {
          cancelWaitlistCountList(
            filter:{
              roomTypeId: $roomTypeId
              fromDate: $fromDate
              toDate: $toDate
              inEffect: true
            }
          ) {
            stayDate
            count
          }
        }
      `,
      update: (data) => data.cancelWaitlistCountList.reduce((result, item) => {
        result[item.stayDate] = item.count
        return result
      }, {}),
      variables () {
        return {
          roomTypeId: this.roomTypeId,
          fromDate: this.fromDate,
          toDate: this.toDate
        }
      },
      skip () {
        return this.hideCancelWait
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.room-type-header {
  position: sticky;
  top: 22px;
  z-index: 7;
}
.t-calendar-item__title {
  font-size: 12px !important;
}
.color-item__title{
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 12px;
}
</style>
