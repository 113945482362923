import moment from 'moment'
// components
import BaseFeesPoints from '@/components/FeesPoints/BaseFeesPoints'
import BookingPlanItem from './components/BookingPlanItem'
import AccomItem from './components/AccomItem'
import RoomTypeCalendar from '@/components/FacilityRoomTypeCalendar/RoomTypeCalendar'
import FeesAndPointsRoomType from './components/FeesAndPointsRoomType'
import AccommodationPriceHistoryList from './components/modals/AccommodationPriceHistoryList'
import AccommodationPriceUpdation from './components/modals/AccommodationPriceUpdation'
import AccommodationPriceUpdationAll from './components/modals/AccommodationPriceUpdationAll'
// utils
import { formatDate, formatMonth, formatCurrency } from '@/constants/functions'
import { NON_PRODUCT_LIST } from '@/api/graphql/accommodation-price'
import {
  addMonths,
  getDate,
  getDaysArray,
  getAllDateInBetween2Dates,
  getDayInMonth
  , getLastDateOfMonth
} from '@/utils/handleDate'
import { getAdditionalChargeType } from '@/utils/getAdditionalChargeType'
// vuex
import { mapActions } from 'vuex'
// lodash
import debounce from 'lodash/debounce'
import gql from 'graphql-tag'
import { translateFacilityType, getOrderOfFacilityType } from '@/constants/facilityType'

export const mixin = {
  components: {
    FeesAndPointsRoomType,
    RoomTypeCalendar,
    BaseFeesPoints,
    BookingPlanItem,
    AccomItem,
    AccommodationPriceHistoryList,
    AccommodationPriceUpdation,
    AccommodationPriceUpdationAll
  },
  name: 'FeesAndPoints',
  timeRange: [{
    text: '1週間',
    value: '1 week'
  }, {
    text: '2週間',
    value: '2 weeks'
  }, {
    text: '1ヶ月',
    value: '1 month'
  }, {
    text: '3ヶ月',
    value: '3 months'
  }, {
    text: '6ヶ月',
    value: '6 months'
  }],
  data () {
    return {
      // date queries
      rangeTime: '2 weeks',
      fromDate: new Date().toISOString().substr(0, 10),
      menuDate: false,
      // facility
      selectedFacility: {
        id: undefined,
        name: '未選択'
      },
      //
      isShowAccomList: true,
      isShowBookingList: true,
      isOutOfStock: false,
      // prices
      minPrice: undefined,
      maxPrice: undefined,
      // room
      nonProductList: [],
      // price history modal
      isShowPriceHistoryModal: false,
      hoveredAccom: {
        position: null,
        name: '',
        price: undefined,
        stayDate: null,
        roomTypeId: null
      },
      // price updation modal
      isShowPriceUpdation: false,
      selectedAccomItem: {
        toPrice: undefined,
        stayDate: null,
        roomTypeId: null,
        priceColumn: undefined,
        updatePrice: undefined
      },
      // Change all at once
      isShowUpdationAll: false,
      stayDateUpdationAll: null
    }
  },
  computed: {
    toDate () {
      return moment(this.fromDate, 'YYYY-MM-DD')
        .add(...this.addMomentArgs)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    facilityListItem () {
      const copied = this.facilityList.slice().map((f, index) => ({ ...f, index }))
      copied.sort((a, b) => {
        const orderByType = getOrderOfFacilityType(a.type) - getOrderOfFacilityType(b.type)
        if (orderByType) return orderByType
        return a.index - b.index
      })
      let currentType = null
      copied.forEach((item, index) => {
        if (item.type !== currentType) {
          currentType = item.type
          copied.splice(index, 0, {
            id: this.facilityList.filter(f => f.type === item.type).map(f => f.id),
            name: translateFacilityType(item.type),
            cssClass: 'select-item-facility-type'
          })
        }
      })

      return copied
    },
    idSelectedFacility: {
      get () {
        return this.selectedFacility ? (
          Array.isArray(this.selectedFacility.id) ? this.selectedFacility.id : [this.selectedFacility.id]
        ) : null
      },
      set (id) {
        const facility = this.facilityListItem.find((item) => item.id === id)
        this.selectedFacility = facility
      }
    },
    addMomentArgs () {
      const [num, period] = this.rangeTime.split(' ')
      return [
        Number(num), period
      ]
    },
    dateListAll () {
      return getAllDateInBetween2Dates(this.fromDate, this.toDate)
    },
    getMonthsList () {
      let lastDayOfFirstMonth = getLastDateOfMonth(this.fromDate)
      if (this.toDate < lastDayOfFirstMonth) {
        lastDayOfFirstMonth = this.toDate
      }
      const months = [
        {
          value: formatMonth(this.fromDate),
          style: `width: calc(150px * ${
            getDayInMonth(lastDayOfFirstMonth) -
            getDayInMonth(this.fromDate) +
            1
          })`
        }
      ]
      const to = moment(this.toDate, 'YYYY-MM-DD')
        .add(1, 'day')
        .format('YYYY-MM-DD')
      for (let i = 1; addMonths(this.fromDate, i) <= to; i++) {
        const date = addMonths(this.fromDate, i)
        let lastDate
        if (date > this.toDate) {
          lastDate = this.toDate
        } else {
          const endOfThisMonth = getLastDateOfMonth(date)
          if (endOfThisMonth <= this.toDate) {
            lastDate = endOfThisMonth
          } else {
            lastDate = this.toDate
          }
        }
        months.push({
          value: formatMonth(date),
          style: `width: calc(150px * ${
            getDayInMonth(lastDate)
          })`
        })
      }
      return months
    }
  },
  methods: {
    formatDate,
    getDate,
    addMonths,
    getDaysArray,
    formatCurrency,
    getAdditionalChargeType,
    ...mapActions(['fetchAccommodationPriceList', 'fetchFacilityList']),
    // ================= HANDLE DATE ========================
    nextMonth () {
      this.fromDate = moment(this.fromDate, 'YYYY-MM-DD')
        .add(...this.addMomentArgs)
        .format('YYYY-MM-DD')
    },
    prevMonth () {
      this.fromDate = moment(this.fromDate, 'YYYY-MM-DD')
        .subtract(...this.addMomentArgs)
        .format('YYYY-MM-DD')
    },
    chooseToday () {
      this.fromDate = this.$today()
    },
    // ================= HANDLE MIN OR MAX PRICE ========================
    // after 300 milliseconds since the last value was changed,
    keyupSearchInput: debounce(async function (e, isMinPrice) {
      const value = e.target.value
      if (isMinPrice) this.minPrice = value
      else this.maxPrice = value
    }, 300),
    // ================= HANDLE ITEM ========================
    updateAllAccom (stayDate) {
      this.isShowUpdationAll = true
      this.stayDateUpdationAll = stayDate
    },
    // ================= HANDLE EVENT ========================
    hoverAccomItem (position, name, priceKey, stayDate, roomTypeId) {
      if (this.isShowPriceUpdation) return
      this.hoveredAccom = {
        position: position,
        name: name,
        priceKey: priceKey,
        stayDate: stayDate,
        roomTypeId: roomTypeId
      }

      this.isShowPriceHistoryModal = true
    },
    clickAccomItem (toPrice, stayDate, roomTypeId, priceColumn, keyValue) {
      this.selectedAccomItem = {
        toPrice: toPrice,
        stayDate: stayDate,
        roomTypeId: roomTypeId,
        priceColumn: priceColumn,
        updatePrice: () => {
          this.resetAndGetNewData([roomTypeId])
        }
      }

      this.isShowPriceUpdation = true
      this.isShowPriceHistoryModal = false
    },
    leaveAccomItem () {
      if (this.isShowPriceHistoryModal) this.isShowPriceHistoryModal = false
    }
  },
  apollo: {
    nonProductList: {
      query: gql`${NON_PRODUCT_LIST}`,
      update: data => data.nonProductList
    }
  }
}
